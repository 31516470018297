import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPageScreen from "./Screens/LandingPageScreen";
import OtpPageScreen from "./Screens/OtpPageScreen";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsConditions from "./component/TermsConditions";
import { ApiName } from "./utils/ApiName";
import { fetchApiGet, fetchApiPost } from "./utils/FetchApi";
import TagManager from "react-gtm-module";
import AwarenessScreen from "./Screens/AwarenessScreen";

const App = () => {
  // states
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-W7NKDL3R",
    };
    TagManager.initialize(tagManagerArgs);
    handleGenerateToken();
  }, []);

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      const response = await fetchApiPost("", `${ApiName.generateToken}`, data);
      if (response.status === 0) {
        setToken(response.response.token);
        console.log(token);
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<LandingPageScreen token={token} />} />
        <Route path="/landing" element={<LandingPageScreen token={token} />} />
        <Route path="/pin" element={<OtpPageScreen token={token} />} />
        <Route path="/awareness" element={<AwarenessScreen token={token} />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
