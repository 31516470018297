import React from "react";
import "../styles/LandingPage.css";
import logo from "../Assets/imgs/logo.webp";
import image from "../Assets/imgs/main-image.webp";

type Props = {
  onSendOtpPressed: () => void;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNo: string;
  errorMsg: string;
  showLoader: boolean;
};

const Landingpage = (props: Props) => {
  return (
    <div className="landingpage">
      <div className="main-content">
        <div className="logo">
          <img src={logo} width={153} height={71} alt="logo" />
        </div>
        <div className="main-img-wraper">
          <div className="image">
            <img src={image} width={340} height={295} alt="goldstar" />
          </div>
        </div>
        <div className="form">
          <div className="input-content">
            <div className="text">
              Get access to
              <br />
              <span>1000+ Apps & Games</span>
              <br />
              with no ads & in-app purchase
            </div>

            {props.errorMsg ? (
              <>
                <p className="invalid">{props.errorMsg}</p>
              </>
            ) : (
              <>
                {props.showLoader ? (
                  <div className="lp-loader">Loading...</div>
                ) : (
                  <button onClick={props.onSendOtpPressed}>Play Now</button>
                )}
              </>
            )}
          </div>

          <div className="form-text">
            <h6>Only Rs.12/Day (Including VAT , SD , SC)</h6>
            <p>You can cancel your subscription any time</p>
          </div>
        </div>
        <div className="btn-explore">
          <a href="http://ufone.appsclub.com/store/identify?utm_source=ads_digitology/ac">Explore Store</a>
        </div>
        <div className="condition">
          <a href="/terms" target="_blank">
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
